import devKeycloakFile from 'config/keycloak/keycloakDev.json'
import prodKeycloakFile from 'config/keycloak/keycloakProd.json'
import axios from 'axios'

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
}

const param = new URLSearchParams()
param.append('client_id', 'miturno-frontend')
param.append('grant_type', 'password')

const apiRequest = params => {
  !param.has('username') && param.append('username', params?.MAC)
  !param.has('password') && param.append('password', params?.password)
  let postPath = ''
  const keycloak =
    process.env.REACT_APP_ENV === 'production'
      ? prodKeycloakFile['auth-server-url']
      : devKeycloakFile['auth-server-url']
  const realm =
    process.env.REACT_APP_ENV === 'production'
      ? prodKeycloakFile.realm
      : devKeycloakFile.realm

  postPath = `${keycloak}/realms/${realm}/protocol/openid-connect/token`

  return axios.post(postPath, param, config).then(response => {
    return response.data
  })
}

export default apiRequest
