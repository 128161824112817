import React from 'react'

function DeleteIcon({color, size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z" data-name="Trazado 25779"></path>
      <path
        fill="none"
        stroke={color || '#3f3f41'} // Usamos la prop color, si no está definida, utilizamos el color por defecto
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 6L6 18"
        data-name="Trazado 25780"
      ></path>
      <path
        fill="none"
        stroke={color || '#3f3f41'} // Usamos la prop color, si no está definida, utilizamos el color por defecto
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 6l12 12"
        data-name="Trazado 25781"
      ></path>
    </svg>
  )
}

export default DeleteIcon
