import React from 'react'
import NxSpinner from 'components/NxSpinner/NxSpinner'
import CircularProgress from '@material-ui/core/CircularProgress'

export function Spinner(props) {
  return <CircularProgress aria-label="loading" {...props} />
}

export function FullPageSpinner() {
  return (
    <div>
      <NxSpinner loading={true} />
    </div>
  )
}
