import React from 'react'
import PropTypes from 'prop-types'
import {toast} from 'react-toastify'
import DeleteIcon from 'components/Icons/DeleteIcon'
import IconButton from '@material-ui/core/IconButton'
export const CloseButton = ({closeToast, type}) => (
  <IconButton edge="end" aria-label="toggle" onClick={closeToast}>
    <div
      style={{
        background: type === 'error' ? '#FF3737' : '#388E3C',
        borderRadius: '50%',
        padding: '4%',
        margin: 0,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <DeleteIcon color="white" size="18" style={{padding: '2%'}} />
    </div>
  </IconButton>
)
CloseButton.propTypes = {
  closeToast: PropTypes.func,
}
const ToastComponent = ({type, msg}) => {
  const toastStyle = {
    backgroundColor: type === 'error' ? '#FEB8B8' : '#c0eccb',
    borderRadius: '5px',
    minHeight: '40px',
    padding: '2%',
    marginLeft: '4%',
    color: 'black',
    textAlign: 'center',
  }
  return (
    <div style={toastStyle}>
      <span>{msg}</span>
    </div>
  )
}
ToastComponent.propTypes = {
  closeToast: PropTypes.func,
  type: PropTypes.string,
  msg: PropTypes.string,
}
export default function useNotify() {
  const notify = (msg, options) => {
    const defaultOptions = {
      autoClose: 8000, // Tiempo de cierre automático en milisegundos
      containerStyle: {
        backgroundColor: 'white',
      },
    }
    const toastOptions = {...defaultOptions, ...options}
    return toast(<ToastComponent {...options} msg={msg} />, toastOptions)
  }
  return {notify}
}
