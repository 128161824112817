/*eslint-disable*/
import React, {useCallback, useState, useEffect, useRef} from 'react'
import axios from 'axios'
import {useAuth} from './context/auth-context'
import {useUser} from './context/user-context'
import {useAsync} from 'react-async'
import {FullPageSpinner} from './components/lib'
import {createMuiTheme} from '@material-ui/core/styles'
import {ThemeProvider} from '@material-ui/styles'
import useNotify from 'utils/customHooks/useNotify'
import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom'
import apiRequest from 'utils/nxlibrary/request/tokenRequest'
import {useMutation} from '@tanstack/react-query'
import {loginDevice} from 'utils/auth-client'

const ScreensWeb = React.lazy(() => import('layouts/ScreensWeb.js'))
const StandTicket = React.lazy(() => import('layouts/StandTicket.js'))
const TicketWeb = React.lazy(() => import('layouts/TicketWeb.js'))
const AuthenticatedApp = React.lazy(() => import('./authenticated-app'))
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

const getMuiTheme = () =>
  createMuiTheme({
    typography: {
      fontFamily: "'Lato', sans-serif",
    },
  })

function App(props) {
  const apiClient = {props}
  const [triggerToken, setTriggerToken] = useState(false)
  const [timeToRender, setTimeToRender] = useState(false)
  const user = useUser()
  const {login} = useAuth()
  const onLogin = useCallback(login, [])
  const {notify} = useNotify()
  const isToken = useRef(false)

  /* Validate if user wants to access turn_web view */
  let turn_web

  if (process.env.REACT_APP_ENV === 'development') {
    turn_web = window.location.pathname.split('/')[2]
  } else if (process.env.REACT_APP_ENV === 'production') {
    turn_web = window.location.pathname.split('/')[1]
  }

  /* Answer from APP should be something like:
  {
    "user" : "screens", || "kiosks"
    "MAC" : "XX:XX:XX:XX:XX:XX",
    "password" : "xxxxxxxxxx",
  }
  */

  function requestCredentials() {
    return axios.get(`http://127.0.0.1:1234/?credentials`).then(response => {
      return response.data.data
    })
  }

  const params = new URLSearchParams()
  params.append('client_id', 'miturno-frontend')
  params.append('grant_type', 'password')

  const {
    isPending: isPendingCredentials,
    run: reqCredentials,
    data: credentials,
  } = useAsync({
    deferFn: requestCredentials,
    onResolve: () => {
      notify('Credenciales desde APP solicitadas con éxito', {
        type: 'success',
      })
      setTriggerToken(true)
    },
  })

  async function tokenRequest() {
    mutate({foo: 'token'})
  }

  /**
   * Endpoint call for login first time in kiosk or screen
   */
  const {mutate, isLoading} = useMutation({
    retry: 1,
    retryDelay: attempt =>
      Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    mutationFn: () => {
      return apiRequest(credentials[0])
    },
    onSuccess: res => {
      loginDevice(res.access_token, res.refresh_token, credentials[0].user)
      notify('Usuario conectado exitosamente', {
        type: 'success',
      })
      isToken.current = true
    },
    onError: () => {
      notify('Usuario o contraseña incorrecta', {
        type: 'error',
      })
    },
  })

  function validateCredentials() {
    return credentials && tokenRequest()
  }

  useEffect(() => {
    var timer
    reqCredentials()
    timer = setTimeout(function() {
      setTimeToRender(true)
      clearTimeout(timer)
    }, 1000)
  }, [])

  useEffect(() => {
    validateCredentials()
  }, [triggerToken])

  return (
    <>
      {isPendingCredentials || isLoading || (!isToken && <FullPageSpinner />)}
      {timeToRender ? (
        !triggerToken ? (
          <ThemeProvider theme={getMuiTheme()}>
            <React.Suspense fallback={<FullPageSpinner />}>
              {user ? (
                <AuthenticatedApp />
              ) : turn_web === 'turn_web' ? (
                <React.Suspense fallback={<FullPageSpinner />}>
                  <Router
                    basename={
                      process.env.REACT_APP_ENV === 'development'
                        ? '/miturno'
                        : ''
                    }
                  >
                    <Route
                      path="/turn_web/:organizationId"
                      component={TicketWeb}
                    />
                  </Router>
                </React.Suspense>
              ) : (
                <UnauthenticatedApp onLogin={onLogin} />
              )}
            </React.Suspense>
          </ThemeProvider>
        ) : credentials[0].user === 'screens' ? (
          <React.Suspense fallback={<FullPageSpinner />}>
            <Router
              basename={
                process.env.REACT_APP_ENV === 'development' ? '/miturno' : ''
              }
            >
              <Route
                path="/screens_web/:template"
                render={props => (
                  <ScreensWeb {...props} credentials={credentials} />
                )}
              />
              <Redirect to="/screens_web/t1" />
            </Router>
          </React.Suspense>
        ) : (
          <React.Suspense fallback={<FullPageSpinner />}>
            <Router
              basename={
                process.env.REACT_APP_ENV === 'development' ? '/miturno' : ''
              }
            >
              <Route
                path="/atril"
                render={props => <StandTicket {...props} />}
              />
              <Redirect to="/atril" />
            </Router>
          </React.Suspense>
        )
      ) : (
        /* Hold on 1s */ <FullPageSpinner />
      )}
    </>
  )
}

export default App
