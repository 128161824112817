import io from 'socket.io-client'
import {getToken} from './auth-client'
import keycloak from 'config/keycloakClient'

let envURL = process.env.REACT_APP_API_URL
let socketURL = ''
let pathSocket = ''

switch (envURL) {
  case 'http://localhost:51100':
    socketURL = 'http://localhost:51120'
    break
  case 'https://api.development.netuxcloud.com/miturno':
    socketURL = 'https://api.development.netuxcloud.com'
    pathSocket = '/miturno/socket.io'
    break
  case 'https://api.miturno2.netuxcloud.com':
    socketURL = 'https://api.miturno2.netuxcloud.com'
    pathSocket = '/socket.io'
    break
  case 'https://api.miturno2clone.netuxcloud.com':
    socketURL = 'https://api.miturno2clone.netuxcloud.com'
    pathSocket = '/socket.io'
    break
  default:
}

let WsRt
const WsRTConnect = token => {
  WsRt = io(socketURL, {
    path: pathSocket,
    transports: ['websocket', 'polling'],
    query: {
      token: token,
    },
  })

  WsRt.on('connect', () => {
    console.log('socket connected')
  })

  WsRt.on('disconnect', reason => {
    console.log(`socket disconnect: ${reason}`)
  })

  WsRt.on('connect_error', err => {
    console.log(`socket connect_error: ${err}`)
    const isTokenExpired = keycloak.isTokenExpired()
    if (!isTokenExpired) {
      WsRt.io.opts.query.token = getToken()
      WsRt.connect()
    }
  })

  WsRt.io.on('reconnect_attempt', () => {
    console.log('socket reconnect_attempt')
  })

  WsRt.io.on('reconnect', () => {
    console.log('socket reconnect')
  })
}

export {WsRTConnect, WsRt}
