/* import client from './api-client' */
import keycloak from 'config/keycloakClient'
import {apiClient} from 'utils/api'
import {WsRTConnect} from '../utils/wsRt'

const jwt = require('jsonwebtoken')

/* function handleUserResponse({user: {token, ...user}}) {
  window.localStorage.setItem('__token__', token)
  return user
} */

function getUser() {
  if (!keycloak.tokenParsed) {
    return Promise.resolve(null)
  }

  return new Promise(resolve => {
    keycloak
      .loadUserProfile()
      .success(response => {
        const roles = keycloak.tokenParsed.resource_access[
          'miturno-frontend'
        ].roles.concat(keycloak.tokenParsed.realm_access.roles)
        resolve({
          user: {
            ...response,
            roles,
          },
        })
      })
      .catch(() => {
        resolve(null)
      })
  })
}

function login(/* {username, password} */) {
  return new Promise((resolve, reject) => {
    keycloak
      .init({onLoad: 'login-required'})
      .then(authenticatedLocal => {
        apiClient.setToken(keycloak.token)
        const decoded = jwt.decode(keycloak.token, {complete: true})
        if (decoded.payload.claims?.headquarterId) WsRTConnect(keycloak.token)
        resolve(authenticatedLocal)
      })
      .catch(() => {
        reject(null)
      })

    keycloak.onTokenExpired = () => {
      keycloak
        .updateToken(60)
        .then(refreshed => {
          if (refreshed) {
            apiClient.setToken(keycloak.token)
          } else {
            console.info('Token is still valid')
          }
        })
        .catch(() => {
          console.error(
            'Failed to refresh the token, or the session has expired',
          )
        })
    }
  })

  //   return client('login', {body: {username, password}}).then(handleUserResponse)
}

/**
 *
 * @param {*} token  is token generate for endpoint login in screen and kiosk
 * @param {*} refresh  is refresh token generate for endpoint login in screen and kiosk
 * @param {*} user is identify type user kiosk or screen for validate conection socketio in case screen
 * This function create conection an keycloak and suscribe for refresh token automatic
 */
function loginDevice(token, refresh, user) {
  return new Promise((resolve, reject) => {
    keycloak
      .init({
        onLoad: 'check-sso',
        token: token,
        refreshToken: refresh,
        //enableLogging: true /**using for show logs keycloak */,
        scope: 'offline_access',
        checkLoginIframe: false,
      })
      .then(authenticatedLocal => {
        apiClient.setToken(keycloak.token)
        if (user === 'screens') {
          WsRTConnect(keycloak.token)
        }
        resolve(authenticatedLocal)
      })
      .catch(() => {
        reject(null)
      })

    keycloak.onTokenExpired = () => {
      keycloak
        .updateToken(60)
        .then(refreshed => {
          if (refreshed) {
            apiClient.setToken(keycloak.token)
          } else {
            console.info('Token is still valid')
          }
        })
        .catch(() => {
          console.error(
            'Failed to refresh the token, or the session has expired',
          )
          const eventOffline = window.addEventListener('online', () => {
            const isTokenExpired = keycloak.isTokenExpired()
            if (isTokenExpired) {
              keycloak.updateToken(60).then(refreshed => {
                if (refreshed) {
                  apiClient.setToken(keycloak.token)
                }
              })
            }
          })
          window.removeEventListener('online', eventOffline)
        })
    }
  })
}

/* function register({username, password}) {
  return client('register', {body: {username, password}}).then(
    handleUserResponse,
  )
} */

function logout() {
  // window.localStorage.removeItem(localStorageKey)
  keycloak.logout()
  return Promise.resolve()
}

function getToken() {
  return keycloak.token
}

export {login, /* register,  */ logout, getToken, getUser, loginDevice}
