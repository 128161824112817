import React from 'react'
import {ToastContainer, Slide} from 'react-toastify'

// Others
import PropTypes from 'prop-types'

import {AuthProvider} from './auth-context'
import {UserProvider} from './user-context'
import {CloseButton} from 'utils/customHooks/useNotify'

// import SocketProvider from "./socket-context";
import 'react-toastify/dist/ReactToastify.css'
import 'assets/scss/material-dashboard-pro-react/plugins/_plugin-react-tostify.scss'

function AppProviders({children}) {
  return (
    <AuthProvider>
      <UserProvider>
        {/* <SocketProvider> */}
        <ToastContainer closeButton={CloseButton} transition={Slide} />
        {children}
        {/* </SocketProvider> */}
      </UserProvider>
    </AuthProvider>
  )
}
AppProviders.propTypes = {
  children: PropTypes.node,
}
export default AppProviders
