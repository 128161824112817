import React from 'react'
import ReactDOM from 'react-dom'
import 'assets/scss/material-dashboard-pro-react.scss?v=1.8.0'
import App from './App'
import AppProviders from './context'
import {apiClient} from 'utils/api'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <>
      <AppProviders>
        <App apiClient={apiClient} />
      </AppProviders>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  </QueryClientProvider>,
  document.getElementById('root'),
)
